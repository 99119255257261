* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url("/images/img-2.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url("/images/img-1.jpg"); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url("/images/img-8.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.page-container {
  margin: 0 auto;
  justify-content: center;
  width: 60%;
}
label {
  display: block;
  margin-top: 10px;
}

input {
  width: 100%;
  padding: 8px 20px;
  border-radius: 10px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  border: 1px solid #242424;
}

select {
  padding: 8px 20px;
  border-radius: 10px;
}

button {
  margin-top: 10px;
}

.service-row {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
